<template>
    <a-modal
        :title="`批量${batchData.result==2?'通过':'拒绝'}`"
        width='50%'
        :visible="visible"
        :confirmLoading="confirmLoading"
        :destroyOnClose="true"
        @ok="handleAuditBatch"
        @cancel="handleCancel"
    >
        <a-form :form="form">
            <!-- 批量审核备注 -->
            <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input
                    v-decorator="['remark',{rules: [{ required: batchData.result=='2' ? false : true, message: '请输入！' }]}]"
                    placeholder="请输入"
                    type="textarea"
                />
            </a-form-item>
          </a-form>
    </a-modal>
</template>

<script>
import { auditBatch } from '@/api/modular/mallLiving/check/enterCheck'

export default {
    data(){
        return {
            visible: false, //modal框显示状态
            confirmLoading: false,
            batchData:{},   //父组件传过来的数据
            form: this.$form.createForm(this),
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        }
    },
    methods:{
        //初始化方法
        batch(data){
            this.visible = true
            this.batchData = data
        },
        // 点击遮罩层或右上角叉或取消按钮的操作
        handleCancel(){
            this.visible = false
            this.confirmLoading = false
            this.form.setFieldsValue({
                remark:''
            })
        },
         //批量审核
        handleAuditBatch() {
            this.form.validateFields((errors, values) => {
                if(!errors){
                    // 如果没有选中项，直接return,不发送请求
                    if(this.batchData.ids.length == 0){ 
                        this.$message.error("无选中项")
                        return
                     }
                    auditBatch({...this.batchData, remark:values.remark}).then((res) => {
                        if(res.success){
                            let text = this.batchData.result==2 ? '批量通过成功' : '批量拒绝成功'
                            this.$message.success(text)
                            this.$emit("ok")
                            this.handleCancel()
                        }else{
                            this.$message.error("操作失败")
                            setTimeout(()=>{
                                this.confirmLoading = false
                            },600)
                        }
                    })
                    // .finally((res)=>{
                    //     setTimeout(()=>{

                    //     },1000)
                    // })
                }
            })
            
        }
    }
}
</script>
<style lang="less" scoped>
    .mytable{
        margin-bottom:70px;
        border-collapse:collapse;
        width:100% ;
        height:250px;
        .title {
            background: rgb(207, 248, 248);
            width:20%
        }
        td{
            border:2px solid rgb(228, 225, 225);
            padding-left:7px;
            font-size:15px;
        }
    }
    img{
        width:100px;
        height: 50px;
    }
    .flex {
        display: flex;
    }
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }
</style>