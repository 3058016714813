<template>
    <a-modal title="详情" width='50%' :visible="visible" :destroyOnClose="true" @cancel="handleCancel" :footer="null">
        <a-spin :spinning="confirmLoading">
            <a-row>
                <a-form layout="inline">
                    <a-divider>店铺信息</a-divider>
                    <a-col :md="12" :sm="24">
                        <a-form-item label="认证类型">
                            <span>{{ details.type == 1 ? '个人' : details.type == 2 ? '个体工商户' : details.type == 3 ? '公司企业'
                                    : ''
                            }}</span>
                        </a-form-item>
                    </a-col>

                    <a-col :md="12" :sm="24">
                        <a-form-item label="店铺名称">
                            <span>{{ details.storeName }}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item label="用户姓名">
                            <span>{{ details.realName }}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item label="用户昵称">
                            <span>{{ details.nickName }}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item label="手机号">
                            <span>{{ details.mobile }}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="12" :sm="24">
                        <a-form-item label="经营类目">
                            <span>{{ details.categoryName }}</span>
                        </a-form-item>
                    </a-col>
                    <a-row v-show="details.type == 2 || details.type == 3">
                        <a-col :md="12" :sm="24">
                            <a-form-item label="营业执照名称">
                                <span>{{ details.businessLicenseName }}</span>
                            </a-form-item>
                        </a-col>
                        <a-col :md="12" :sm="24">
                            <a-form-item label="营业执照注册号">
                                <span>{{ details.businessLicenseNo }}</span>
                            </a-form-item>
                        </a-col>
                        <a-col :md="24" :sm="24" v-if="details.imageList && details.imageList.length > 0">
                            <a-form-item label="营业执照照片">
                                <span>
                                    <viewer :images="[details.imageList]">
                                        <img style="border:0.5px solid #bbb; margin:5px; padding:3px;"
                                            v-for="(item, index) in details.imageList" :key="index" :src="item" alt="">
                                    </viewer>
                                </span>
                            </a-form-item>
                        </a-col>
                        <a-col :md="24" :sm="24" v-if="details.beginTime">
                            <a-form-item label="执照有效期">
                                <span>{{ details.beginTime.split(" ")[0] }} ~~ {{ details.endTime ? details.endTime.split(" ")[0] : '永久'}}</span>
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-divider>实名认证信息：</a-divider>
                        <a-col :md="12" :sm="24" v-if="details.realNameResult">
                            <a-form-item label="申请人姓名">
                                <span>{{ details.realNameResult.realName }}</span>
                            </a-form-item>
                        </a-col>
                        <a-col :md="12" :sm="24" v-if="details.realNameResult">
                            <a-form-item label="用户昵称">
                                <span>
                                    {{ details.realNameResult.nickName }}
                                </span>
                            </a-form-item>
                        </a-col>
                        <a-col :md="12" :sm="24" v-if="details.realNameResult">
                            <a-form-item label="身份证号">
                                <span>{{ details.realNameResult.idNo }}</span>
                            </a-form-item>
                        </a-col>
                        <a-col :md="12" :sm="24" v-if="details.realNameResult">
                            <a-form-item label="手机号码">
                                <span>{{ details.realNameResult.mobile }}</span>
                            </a-form-item>
                        </a-col>
                        <a-col :md="12" :sm="24" v-if="details.realNameResult">
                            <a-form-item label="身份证正面">
                                <span>
                                    <viewer :images="[details.realNameResult.idFront]">
                                        <img :src="details.realNameResult.idFront" alt="">
                                    </viewer>
                                </span>
                            </a-form-item>
                        </a-col>
                        <a-col :md="12" :sm="24" v-if="details.realNameResult">
                            <a-form-item label="身份证反面">
                                <span>
                                    <viewer :images="[details.realNameResult.idBack]">
                                        <img :src="details.realNameResult.idBack" alt="">
                                    </viewer>
                                </span>
                            </a-form-item>
                        </a-col>
                        <a-col :md="24" :sm="24"  v-if="details.realNameResult">
                            <a-form-item label="身份证日期">
                                <span>{{ details.realNameResult.idBeginTime ? details.realNameResult.idBeginTime.split("
                                ")[0] : ''}}{{ 
                                            '~~' + (details.realNameResult.idEndTime ? details.realNameResult.idEndTime.split(" ")[0] : '永久')
                                    }}</span>
                            </a-form-item>
                        </a-col>
                        <a-divider>审核状态：</a-divider>
                        <a-col :md="12" :sm="24">
                            <a-form-item label="审核结果">
                                <span
                                    :style="{ color: details.result == 1 ? 'orange' : details.result == 2 ? 'green' : details.result == 3 ? 'red' : '' }">
                                    {{ details.result == 1 ? '待审核' : details.result == 2 ? '已通过' : details.result == 3 ?
                                            '已拒绝' : ''
                                    }}
                                </span>
                            </a-form-item>
                        </a-col>
                        <a-col :md="24" :sm="24" v-if="details.result != 1">
                            <a-form-item label="审核备注">
                                <span>{{ details.remark }}</span>
                            </a-form-item>
                        </a-col>
                    </a-row>

                </a-form>
            </a-row>
        </a-spin>
    </a-modal>
</template>

<script>
import { auditDetail } from '@/api/modular/mallLiving/check/enterCheck'

export default {
    data() {
        return {
            visible: false, //modal框显示状态
            confirmLoading: false,
            previewVisible: false,
            previewVisible1: false,
            id: '',
            details: {},
            form: this.$form.createForm(this),
        }
    },
    methods: {
        //初始化方法
        detail(record) {
            this.visible = true
            this.confirmLoading = true
            //获取详情数据
            setTimeout(() => {
                auditDetail({ id: record.id }).then((res) => {
                    if (res.success) {
                        this.details = res.data
                        this.details.imageList = this.details.businessLicenseImages ? this.details.businessLicenseImages.split(',') : []
                        this.confirmLoading = false
                        console.log(this.details)
                    }
                }).finally((res) => {
                    setTimeout(() => {
                        this.confirmLoading = false
                    }, 5000)
                })
            }, 100)

        },
        // 点击遮罩层或右上角叉或取消按钮的操作
        handleCancel() {
            this.visible = false
            this.details = {} //关闭之后清空
        }
    }
}
</script>
<style lang="less" scoped>
.mytable {
    margin-bottom: 70px;
    border-collapse: collapse;
    width: 100%;
    height: 250px;

    .title {
        background: rgb(207, 248, 248);
        width: 20%
    }

    td {
        border: 2px solid rgb(228, 225, 225);
        padding: 7px;
        font-size: 15px;
        width: 30%;
    }
}

img {
    height: 100px;
}
</style>